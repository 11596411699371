import request from '@/utils/axios'

export function reportList (params) {
  return request({
    url: `/api/console/report`,
    method: 'get',
    params: params
  })
}

export function reportDetail (id) {
  return request({
    url: `/api/console/report/${id}`,
    method: 'get',
  })
}

export function reportChangeStatus (id, status) {
  return request({
    url: `/api/console/report/${id}`,
    method: 'post',
    data: {
      state: status
    }
  })
}