<template>
  <div>

    <Head
      v-model="query"
      @handleRefresh="init"
    />
    <Table
      :tableData="pageData.data"
      :editable="editable"
      @handleRefresh="init"
    />
    <Pagination
      :query="query"
      :pageData="pageData"
      @handleQuery="handleQuery"
      @handleRefresh="init"
    />
  </div>
</template>

<script>
import { forIn, has } from 'lodash'
import resStatus from '@/constants/resStatus'
import { reportList } from '@/api/admin/report'
import { adminLogout } from '@/utils/admin'

import Head from './Head'
import Table from './Table'
import Pagination from '@/components/admin/pagination'

export default {
  components: {
    Head,
    Table,
    Pagination
  },
  data () {
    return {
      query: {
        page: 1,
        pageSize: 10,
        keyword: '',
        from: new Date(new Date().getTime() - 3600 * 1000 * 24 * 30).toISOString().slice(0, 10),
        to: new Date().toISOString().slice(0, 10),
        state: '',
        type: ''
      },
      editable: true,
      pageData: {
        data: []
      },
      showPreview: false,
      previewContent: null
    }
  },
  methods: {
    init () {
      reportList(this.query).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.pageData = res
            break
          default:
            adminLogout()
            this.$message.error(res.message)
        }
      }).catch(err => {
        adminLogout()
        this.$message.error(err)
      })
    },
    handleQuery (obj) {
      forIn(obj, (val, key) => {
        if (has(this.query, key)) {
          this.query[key] = val
        }
      })
    },
    handleEditable (boo) {
      this.editable = boo
    },
    handlePreview (obj) {
      this.previewContent = obj
      this.showPreview = true
    },
    toggleDialog (boo) {
      this.showPreview = boo
    }
  },
  created () {
    this.init()
  },
  watch: {
    $route (to, from) {
      this.init()
    }
  }
}
</script>
