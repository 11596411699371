<template>
  <div>
    <el-table
      ref="listTable"
      :data="tableData"
    >
      <el-table-column
        prop="reporter_username"
        label="检举人用户名"
        min-width="150"
        align="center"
      />
      <el-table-column
        prop="content"
        label="检举内容"
        min-width="200"
        align="center"
      />
      <el-table-column
        prop="reported_username"
        label="被检举用户名"
        min-width="150"
        align="center"
      />
      <el-table-column
        prop="created_at"
        label="反馈时间"
        min-width="150"
        align="center"
      />
      <el-table-column
        label="表单状态"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          {{ handleState(scope.row.state) }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        min-width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button-group>
            <el-tooltip
              effect="dark"
              content="设置"
              placement="top"
            >
              <el-button
                :disabled="!editable"
                type="success"
                icon="el-icon-edit"
                @click="handleDialog(scope.row)"
              />
            </el-tooltip>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      class="table-view-modal"
      :visible.sync="dialogVisible"
      width="800px"
      lock-scroll
      modal
      close-on-click-modal
      destroy-on-close
    >
      <el-container>
        <el-header>
          <h3 style="text-align:center;">设置</h3>
        </el-header>
        <el-main>
          <div class="text-left">
            检举人用户名：{{ contain ? contain.reporter_username : '' }}<br><br>
            被检举用户名：{{ contain ? contain.reported_username : '' }}<br><br>
            检举内容：{{ contain ? contain.content : '' }}
          </div>
        </el-main>
        <el-footer v-if="isShowDialogFooter">
          <el-button
            :disabled="contain && contain.state === 'deleted'"
            type="danger"
            @click="handleChangeState('deleted')"
          >
            删除内容
          </el-button>
          <el-button
            :disabled="contain && contain.state === 'conform'"
            type="success"
            @click="handleChangeState('conform')"
          >
            无违规
          </el-button>
        </el-footer>
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { reportChangeStatus, reportDetail } from '@/api/admin/report'

export default {
  data () {
    return {
      dialogVisible: false,
      contain: {
        remark: null
      },
      stateOption: [
        {
          label: '未读取',
          value: 'init'
        },
        {
          label: '已读取',
          value: 'read'
        },
        {
          label: '已删除',
          value: 'deleted'
        },
        {
          label: '无违规',
          value: 'conform'
        }
      ]
    }
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isShowDialogFooter() {
      return this.contain.state !== 'deleted' && this.contain.state !== 'conform' && this.contain?.state !== undefined
    }
  },
  methods: {
    handleState(rowState) {
      return this.stateOption.find(it => it.value === rowState).label
    },
    handleDialog(row) {
      this.clearDialog()
      this.dialogVisible = true
      reportDetail(row.id).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.contain = res.data
            this.$emit('handleRefresh')
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    handleChangeState (status) {
      reportChangeStatus(this.contain.id, status)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              this.$message.success('操作成功')
              this.$emit('handleRefresh')
              this.dialogVisible = false
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
          this.handleEditable(true)
        })
        .catch(err => {
          this.$message.error(err)
          this.handleEditable(true)
        })
    },
    handleEditable (boo) {
      this.$emit('handleEditable', boo)
    },
    clearDialog() {
      this.contain = {
        remark: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-view-modal {
  max-width: 100%;
  .el-header {
    text-align: left;
    max-height: auto;
    h3 {
      font-size: 1.75em;
    }
  }
  .el-main {
    padding-top: 0;
  }
}
</style>
