<template>
  <el-card
    shadow="never"
    class="text-left"
  >
    <el-form
      :inline="true"
      label-width="80px"
    >
      <el-form-item>
        <el-input
          v-model="value.keyword"
          clearable
          placeholder="请输入关键字"
        />
      </el-form-item>
      <el-form-item label="查询时间">
        <el-date-picker
          v-model="value.from"
          type="date"
          placeholder="开始时间"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          align="left"
          style="width: 180px;"
        />
        至
        <el-date-picker
          v-model="value.to"
          type="date"
          placeholder="結束时间"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          align="left"
          style="width: 180px;"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="handleSearch"
        >
          查询
        </el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  model: {
    prop: 'value', // 定義v-model接收到的變數名
    event: 'eventChange' // 定義v-model修改新值的事件名
  },
  props: {
    value: {
      type: Object,
      value: ''
    }
  },
  data() {
    return {
      query: this.value
    }
  },
  watch: {
    query(newValue) {
      this.$emit('eventChange', newValue)
    }
  },
  methods: {
    handleSearch() {
      this.$emit('eventChange', { ...this.value, ...{ page: 1 } })
      this.$emit('handleRefresh')
    }
  }
}
</script>
<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0 !important;
}
</style>
